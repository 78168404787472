import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";

const url = "/api/parties";

@Injectable({
  providedIn: 'root'
})
export class PartyService {

  public constructor(private httpClient: HttpClient) {
  }

  public read() {
    return this.httpClient.get(url);
  }

}
