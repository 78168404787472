import {Component, OnInit} from '@angular/core';
import {CandidateModel} from "../model/candidate/candidate.model";
import {CandidateListService} from "../service/candidate-service/candidate-list.service";
import {ToastrService} from "ngx-toastr";
import {Router} from "@angular/router";

@Component({
  selector: 'app-candidate-list',
  templateUrl: './candidate-list.component.html',
  styleUrls: ['./candidate-list.component.scss']
})
export class CandidateListComponent implements OnInit {

  candidates: CandidateModel[] = [];

  slideConfig = {
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  constructor(
    private candidateListService: CandidateListService,
  ) {

  }

  ngOnInit() {
    this.getCandidates();
  }

  getCandidates() {
    this.candidateListService.getCandidates().subscribe(c => this.candidates = c);
  }

  onVote(voted: boolean) {
    if (voted) {
      this.getCandidates();
    }
  }


}
