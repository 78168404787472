import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";

const url = "/api/municipalities";

@Injectable({
  providedIn: 'root'
})
export class MunicipalityService {

  constructor(private httpClient: HttpClient) { }

  public read() {
    return this.httpClient.get(url);
  }

}
