import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CandidateModel} from "../../model/candidate/candidate.model";
import {CandidateListService} from "../../service/candidate-service/candidate-list.service";
import {ToastrService} from "ngx-toastr";
import {LoginService} from "../../service/login/login.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-candidates',
  templateUrl: './candidates.component.html',
  styleUrls: ['./candidates.component.scss']
})
export class CandidatesComponent implements OnInit {

  @Input() candidate: CandidateModel;

  @Output() private afterVoteCallback = new EventEmitter<boolean>();

  constructor(
    private router: Router,
    private candidateListService: CandidateListService,
    private toaster: ToastrService,
  ) {

  }

  ngOnInit() {
  }

  vote() {
    if (localStorage.getItem('Authorization') == null) {
      this.router.navigate(['/login']);
      return;
    }
    if (window.confirm('Сигурни ли сте, че искате да гласувате (можете да гласувате само за един кандидат)?')) {
      this.candidateListService.vote(this.candidate.id).subscribe(res => {
        if (res === false) {
          this.toaster.error("Вие вече сте гласували!");
          return;
        }
        this.toaster.success('Гласувахте успешно!');
        this.afterVoteCallback.emit(true);
      }, error => {
        this.toaster.error(error);
      })
    }

  }

  redirectToImage() {

  }
}
