import {HttpClient} from '@angular/common/http';
import {RegisterModel} from '../../model/register.model';
import {Injectable} from '@angular/core';

const url = '/api/users';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  public constructor(private httpClient: HttpClient) {
  }

  public register(registerModel: RegisterModel) {
    return this.httpClient.post(url, registerModel);
  }
}
