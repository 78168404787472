import {Pipe, PipeTransform} from "@angular/core";


@Pipe({
  name: "percentage"
})
export class PercentagePipe implements PipeTransform{

  transform(value: string) {
    return (parseFloat(value)*100).toPrecision(2) + '%';
  }

}
