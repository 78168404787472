import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {PartyService} from "../service/party/party.service";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-party',
  templateUrl: './party.component.html',
  styleUrls: ['./party.component.scss']
})
export class PartyComponent implements OnInit, AfterViewInit {

  @Input('parties')
  public parties: any;

  constructor(private partyService: PartyService, private sanitizer: DomSanitizer) {
  }

  ngOnInit() {
  }

  private read() {

    if (this.parties === undefined) {
      this.partyService.read().subscribe((next) => {
        this.parties = next;

      });
    }
  }

  getWidth(votesPercentage: any) {
    return this.sanitizer.bypassSecurityTrustStyle(votesPercentage * 100 + '%');
  }

  ngAfterViewInit(): void {
    this.read();
  }

}


