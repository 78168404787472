import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from "@angular/router";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'mdb-angular-free';

  public constructor(
    private router: Router,
  ) {

  }

  ngOnInit() {
  }

  logout() {
    localStorage.removeItem('Authorization');
    this.router.navigate(['candidate-list']);
  }

  isLogged() {
    return !!localStorage.getItem('Authorization');
  }
}
