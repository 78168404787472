import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {ModalDirective} from 'angular-bootstrap-md';
import {RegisterService} from '../service/register/register.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {RegisterModel} from '../model/register.model';
import {ValidateFn} from "codelyzer/walkerFactory/walkerFn";

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, AfterViewInit {

  @ViewChild('registerForm', {static: true}) private modalDirective: ModalDirective;

  private validatingForm: FormGroup;

  private registerModel: RegisterModel;

  private get registerFormEmail() {
    return this.validatingForm.get('registerFormEmail');
  }

  private get registerFormPassword() {
    return this.validatingForm.get('registerFormPassword');
  }

  private get registerFormPasswordConfirm() {
    return this.validatingForm.get('registerFormPasswordConfirm');
  }

  constructor(
    private registerService: RegisterService,
    private router: Router,
    private toastrService: ToastrService
  ) {
  }

  ngOnInit() {
    this.validatingForm = new FormGroup({
      registerFormEmail: new FormControl('', [
        Validators.required,
        Validators.email,
        Validators.pattern(new RegExp('^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$'))
      ]),
      registerFormPassword: new FormControl('', [
        Validators.required,
        Validators.minLength(3)
      ]),
      registerFormPasswordConfirm: new FormControl('', [])
    });
  }


  public ngAfterViewInit(): void {
    this.modalDirective.show();
    this.modalDirective.onHide.subscribe(generatorOrNext => {
      this.modalDirective.onHide.unsubscribe();
    }, error => {
      console.error(error);
    });
  }

  private registerFormHide() {

    this.modalDirective.hide();
    if (!this.modalDirective.isShown) {
      setTimeout(() => {
        this.router.navigate(['//candidate-list']);
      }, 500);
    }
  }

  private register() {
    if (this.validatingForm.valid) {
      this.registerModel = new RegisterModel(this.registerFormEmail.value, this.registerFormPassword.value, this.registerFormPasswordConfirm.value);
      this.registerService.register(this.registerModel).subscribe(response => {
          this.toastrService.success("Вие се регистрирахте успешно!");
          this.router.navigate(['/candidate-list']);
        }, error => {
        this.toastrService.error('Неуспешно Регистриране');

      });
    }
  }

}

