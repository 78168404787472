import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {AppComponent} from './app.component';

import {MDBBootstrapModule} from 'angular-bootstrap-md';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {IndexComponent} from './index/index.component';
import {RouterModule, Routes} from '@angular/router';
import {ImageCropperModule} from 'ngx-image-cropper';
import {CustomPartyComponent} from './custom-party/custom-party.component';
import {ToastrModule} from 'ngx-toastr';
import {RegisterComponent} from './register/register.component';
import {LoginComponent} from './login/login.component';
import {HttpClientModule} from '@angular/common/http';
import {PartyComponent} from './party/party.component';
import {MunicipalityComponent} from './municipality/municipality.component';
import {CandidateListComponent} from './candidate-list/candidate-list.component';
import {CandidatesComponent} from './candidate-list/candidates/candidates.component';
import {SlickCarouselModule} from "ngx-slick-carousel";
import {PercentagePipe} from "./pipe/percentage.pipe";

const appRoutes: Routes = [
  {path: '', pathMatch: 'full', redirectTo: 'candidate-list'},
  {path: 'index', component: IndexComponent},
  {path: 'custom', component: CustomPartyComponent},
  {path: 'register', component: RegisterComponent},
  {path: 'login', component: LoginComponent},
  {path: 'parties', component: PartyComponent},
  {path: 'municipalities', component: MunicipalityComponent},
  {path: 'candidate-list', component: CandidateListComponent},
  {path: '', redirectTo: 'index', pathMatch: 'full'},
  {path: '**', redirectTo: 'index'},
];

@NgModule({
  declarations: [
    AppComponent,
    IndexComponent,
    IndexComponent,
    CustomPartyComponent,
    RegisterComponent,
    LoginComponent,
    PartyComponent,
    MunicipalityComponent,
    CandidateListComponent,
    CandidatesComponent,
    PercentagePipe,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MDBBootstrapModule.forRoot(),
    FormsModule,
    RouterModule.forRoot(appRoutes),
    ImageCropperModule,
    ReactiveFormsModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(),
    HttpClientModule,
    SlickCarouselModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
