import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ModalDirective} from "angular-bootstrap-md";
import {Router} from "@angular/router";
import {LoginService} from "../service/login/login.service";
import {ToastrService} from "ngx-toastr";
import {LoginModel} from "../model/login/login.model";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {

  @ViewChild('loginForm', {static: true}) private modalDirective: ModalDirective;

  private validatingForm: FormGroup;

  private loginModel: LoginModel;

  public constructor (
    private router: Router,
    private loginService: LoginService,
    private toastrService: ToastrService,
  ) {

  }

  get loginFormModalEmail() {
    return this.validatingForm.get('loginFormModalEmail');
  }

  get loginFormModalPassword() {
    return this.validatingForm.get('loginFormModalPassword');
  }

  ngOnInit() {
    this.validatingForm = new FormGroup({
      loginFormModalEmail: new FormControl('', Validators.email),
      loginFormModalPassword: new FormControl('', Validators.required)
    });
  }

  public ngAfterViewInit(): void {
    this.modalDirective.show();
    this.modalDirective.onHide.subscribe(
      generatorOrNext => {
        this.modalDirective.onHide.unsubscribe();
      },
      error => {
        this.modalDirective.onHide.unsubscribe();
      },
      complete => {
        this.loginFormHide();
        this.modalDirective.onHide.unsubscribe();
      }
    );
  }

  private loginFormHide() {
    this.modalDirective.hide();
    setTimeout(() => {
      this.router.navigate(['//candidate-list']);
    }, 500);

  }

  private login() {
    if (this.validatingForm.valid){
      this.loginModel = new LoginModel(this.loginFormModalEmail.value, this.loginFormModalPassword.value);
      this.loginService.login(this.loginModel).subscribe( res => {
        const token =  res.headers.get('Authorization');
        localStorage.setItem('Authorization', token);
        this.toastrService.success("Login successful")
        this.router.navigate(['//candidate-list']);

      },
        error => {
        console.log(error)
        });
    }
  }



}
