import { Component, OnInit } from '@angular/core';
import {MunicipalityService} from "../service/municipality/municipality.service";

@Component({
  selector: 'app-municipality',
  templateUrl: './municipality.component.html',
  styleUrls: ['./municipality.component.scss']
})
export class MunicipalityComponent implements OnInit {

  private municipalities: any;

  constructor(private municipalityService: MunicipalityService) { }

  ngOnInit() {
    this.municipalityService.read().subscribe(next => {
      this.municipalities = next;

    })
  }

}
