import {Component, OnInit, ViewChild} from '@angular/core';
import {ImageCroppedEvent} from "ngx-image-cropper";
import {CandidateModel} from "../model/candidate/candidate.model";
import {HttpClient} from "@angular/common/http";
import {ToastrService} from "ngx-toastr";

const endPoint = '/api/applications';

@Component({
  selector: 'app-custom-party',
  templateUrl: './custom-party.component.html',
  styleUrls: ['./custom-party.component.scss']
})
export class CustomPartyComponent implements OnInit {

  private candidateName = 'ИМЕ НА КАНДИДАТA';
  private candidateInfoColor = 'black';
  private stateName = '';
  private partyName = 'ИМЕ НА ПАРТИЯТА';
  private position = "КАНДИДАТ ЗА ПАПА НА ОБЩИНА:";
  private listNumber = '69';
  private partyInfoColor = 'black';
  private canvas;
  private context;
  private firstColor: string = 'cyan';
  private secondColor: string = 'blue';
  private imageChangedEvent: any = '';
  private logoChangeEvent: any = '';
  private croppedImage: any = '';
  private logo: any = '';
  private defaultLogo: any = '/assets/1623769_1.jpg';

  @ViewChild('myCanvas', {static: true})
  private myCanvas;
  private candidateInfoBoolean: boolean = false;
  private partyInfoBoolean: boolean = false;
  private colorsInfo:  boolean = false;
  private candidate: CandidateModel;

  private image: string;

  constructor(
    private httpClient: HttpClient,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    this.canvas = this.myCanvas.nativeElement;
    this.context = this.canvas.getContext('2d');
    this.onChange();
  }

  reset(resetFields = false, afterResetHandler = () => null) {
    if (resetFields) {
      this.candidateName = 'ИМЕ НА КАНДИДАТA';
      this.stateName = 'ИМЕ НА ОБЩИНА';
      this.partyName = 'ИМЕ НА ПАРТИЯТА';
      this.position = "КАНДИДАТ ЗА ПАПА НА ОБЩИНА:";
      this. listNumber = '69';
      this.partyInfoColor = 'black';
      this.candidateInfoColor = 'black';
      this.firstColor= 'cyan';
      this.secondColor = 'blue';
      this.croppedImage = null;
      this.logo = null;
    }
    this.image = this.myCanvas.nativeElement.toDataURL('image/png');
    this.onChange();
  }

  uploadFile(event) {
    this.imageChangedEvent = event;
    this.image = this.myCanvas.nativeElement.toDataURL('image/png');
  }

  uploadLogoFile(event) {
    this.logoChangeEvent =  event;
    this.image = this.myCanvas.nativeElement.toDataURL('image/png');
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    const pic = new Image();
    pic.src = this.croppedImage;
    pic.addEventListener('load', () => {
        this.context.drawImage(pic, 50, 280,  361.9, 410.625);
        this.image = this.myCanvas.nativeElement.toDataURL('image/png');
      }
    );
  }

  logoCropped(event: ImageCroppedEvent) {
    this.logo = event.base64;
    const pic = new Image();
    pic.src = this.logo;
    pic.addEventListener('load', () => {
      this.context.drawImage(pic, 50, 50, 120, 120);
      this.image = this.myCanvas.nativeElement.toDataURL('image/png');
    });
  }

  onChange() {
    this.changeGradient();
    this.drawName();
    this.drawLogo();
    this.drawPartyName();
    this.drawTitle();
    this.drawListNumber();
    this.drawImage();
    this.drawWatermark();
    this.image = this.myCanvas.nativeElement.toDataURL('image/png');
  }

  private changeGradient() {
    const gradient = this.context.createLinearGradient(0, 0, 0, 768);
    gradient.addColorStop(0, this.firstColor);
    gradient.addColorStop(1, this.secondColor);
    this.context.fillStyle = gradient;
    this.context.fillRect(0, 0, this.canvas.width, this.canvas.height);
    this.context.font = '200 30px Arial Regular';
    this.context.fillStyle = 'black';
    this.context.fillText('Купуването и продаването на гласове е престъпление.', 130, 750);

    const logoToDraw = new Image();
    logoToDraw.src= this.defaultLogo;

    logoToDraw.addEventListener('load', () => {
      this.context.drawImage(logoToDraw, 50, 50, 120, 120);
      this.image = this.myCanvas.nativeElement.toDataURL('image/png');
    });
  }

  private drawName() {
    this.context.font = '200 30px Arial Regular';
    this.context.fillStyle = this.candidateInfoColor;
    this.context.fillText(this.candidateName, 450, 350);
    this.image = this.myCanvas.nativeElement.toDataURL('image/png');
  }

  private drawLogo() {
    const logoToDraw = new Image();
    logoToDraw.src= this.logo;

    logoToDraw.addEventListener('load', () => {
      this.context.drawImage(logoToDraw, 50, 50, 120, 120);
      this.image = this.myCanvas.nativeElement.toDataURL('image/png');
    });
  }

  private drawPartyName() {
    this.context.font = '600 65px Arial Regular';
    this.context.fillStyle = this.partyInfoColor;
    this.context.fillText(this.partyName, 200, 130);
    this.image = this.myCanvas.nativeElement.toDataURL('image/png');
  }

  private drawTitle() {
    this.context.font = '400 40px Arial Regular';
    this.context.fillStyle= this.candidateInfoColor;
    this.context.fillText(this.position, 50, 250);
    this.image = this.myCanvas.nativeElement.toDataURL('image/png');
  }

  private drawListNumber() {
    this.context.beginPath();
    this.context.lineWidth = '4';
    this.context.strokeStyle = this.partyInfoColor;
    this.context.background = 'white';
    this.context.rect(600, 500, 200, 150);
    this.context.stroke();
    this.context.font = '400 100px Arial Regular';
    this.context.fillStyle = this.partyInfoColor;
    this.context.fillText(this.listNumber, 650, 605);
    this.image = this.myCanvas.nativeElement.toDataURL('image/png');
  }

  private drawImage() {
    const pic = new Image();
    pic.src = this.croppedImage;
    pic.addEventListener('load', () => {
        this.context.drawImage(pic, 50, 280,  361.9, 410.625);
        this.image = this.myCanvas.nativeElement.toDataURL('image/png');
      }
    );
  }

  showCandidateInfo() {
    if (this.candidateInfoBoolean) {
      this.candidateInfoBoolean = false;
      return;
    }
    this.candidateInfoBoolean = true;
    this.partyInfoBoolean = false;
    this.colorsInfo = false;
  }

  showPartyInfo() {
    if (this.partyInfoBoolean) {
      this.partyInfoBoolean = false;
      return;
    }
    this.partyInfoBoolean = true;
    this.candidateInfoBoolean = false;
    this.colorsInfo = false;
  }

  showColorsInfo() {
    if (this.colorsInfo) {
      this.colorsInfo = false;
      return;
    }
    this.colorsInfo = true;
    this.candidateInfoBoolean = false;
    this.partyInfoBoolean = false;
  }

  enroll(imageAnchor: HTMLElement) {

    this.stateName = prompt('Въведете Община');

    const url = this.myCanvas.nativeElement.toDataURL('image/png');
    imageAnchor.setAttribute('href', url);

    const fd = new FormData();
    this.myCanvas.nativeElement.toBlob(b => {
      fd.append('image', b, b.name + '.png');
      fd.append("municipality", this.stateName);
      fd.append("party", this.partyName);
      fd.append("name", this.candidateName);

      this.httpClient.post(endPoint, fd).subscribe(res => {
        this.toastr.success('Вашата кандидатура е приета успешно')

      },
        error => {
        this.toastr.error(error);
        });
    })
  }

  private drawWatermark() {
    this.context.font = '40 40px Arial Regular';
    this.context.fillStyle = 'purple';
    this.context.fillText('Glasuvai.be', 595, 700);
    this.image = this.myCanvas.nativeElement.toDataURL('image/png');
  }

}
