import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {CandidateModel} from "../../model/candidate/candidate.model";
import {Observable} from "rxjs";

const candidatesUrl = '/api/candidates';
const voteUrl = '/api/votes/';

@Injectable({
  providedIn: 'root'
})
export class CandidateListService {

  public constructor(
    private httpClient: HttpClient
  ) {

  }

  getCandidates(): Observable<CandidateModel[]> {
    return this.httpClient.get<CandidateModel[]>('/api/candidates');
  }

  vote (id: number) {
    return this.httpClient.post(voteUrl + id,  {},{
      headers: {
        Authorization: localStorage.getItem('Authorization')
      }
    })
  }

}
