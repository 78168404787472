import {Component, OnInit, ViewChild} from '@angular/core';
import {ImageCroppedEvent} from 'ngx-image-cropper';
import {NavigationEnd, Router} from "@angular/router";
import {CandidateModel} from "../model/candidate/candidate.model";
import {HttpClient} from "@angular/common/http";
import {ToastrService} from "ngx-toastr";

const endPoint = '/api/applications';

@Component({
  selector: 'app-gerb',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {

  private inputName = '';

  private stateName = '';

  private party = '';

  private imageChangedEvent: any = '';

  private croppedImage: any = '';

  private canvas;

  private context;

  private templateSrc: string;

  private nameCoordinateY;

  private nameCoordinateX;

  private stateFirstCoordinateY;

  private stateFirstCoordinateX;

  private stateSecondCoordinateY;

  private stateSecondCoordinateX;

  private imageWidth;

  private imageHeight;

  private imageCoordinateX;

  private imageCoordinateY;

  private cropHeight;

  private cropWidth;

  @ViewChild('myCanvas', {static: true})
  private myCanvas;

  private lastNameBackgroundData: ImageData;

  private lastStateBackgroundData: ImageData;

  private nameColor: string;

  private stateNameColor: string;

  private stateNameFont: string;

  private nameFont: string;

  private transformStateNameFont = (stateName: string) => stateName;

  private fontSize: string;

  private image: string;

  constructor(private router: Router,
              private httpClient: HttpClient,
              private toastr: ToastrService) {
  }

  ngOnInit() {
    this.canvas = this.myCanvas.nativeElement;
    this.context = this.canvas.getContext('2d');
    this.drawTemplate();
    this.router.events.subscribe((next) => {
      if (next instanceof NavigationEnd) {
        this.drawTemplate();
      }
    });
  }

  drawTemplate() {
    this.checkComponent();
    const template = new Image();
    template.src = this.templateSrc;
    template.addEventListener('load', () => {
      this.context.drawImage(template, 0, 0, this.canvas.width, this.canvas.height);
      this.image = this.myCanvas.nativeElement.toDataURL('image/png');
    });
  }

  writeName(event) {
    this.context.font = this.nameFont;
    this.context.fillStyle = this.nameColor;
    this.context.fillText(this.inputName, this.nameCoordinateX, this.nameCoordinateY);
  }

  writeMunicipality() {
    this.context.font = this.stateNameFont;
    this.context.fillStyle = this.stateNameColor;
    if (this.router.url.endsWith('gerb')) {
      this.context.fillText('ЗА ' + this.stateName.toUpperCase(), this.stateFirstCoordinateX, this.stateFirstCoordinateY);
      this.context.fillText('Община ' + this.stateName, this.stateSecondCoordinateX, this.stateSecondCoordinateY);
    } else {
      this.context.fillText(this.transformStateNameFont(this.stateName), this.stateFirstCoordinateX, this.stateFirstCoordinateY);
    }

  }

  reset(resetFields = false, afterResetHandler = () => null) {
    if (resetFields) {
      this.inputName = '';
      this.stateName = '';
      this.croppedImage = null;
    }
    this.checkComponent();
    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
    const template = new Image();
    template.src = this.templateSrc;
    template.addEventListener('load', () => {
      this.context.drawImage(template, 0, 0, this.canvas.width, this.canvas.height);
      afterResetHandler();
      this.image = this.myCanvas.nativeElement.toDataURL('image/png');
    });
  }

  uploadFile(event) {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    const pic = new Image();
    pic.src = this.croppedImage;
    pic.addEventListener('load', () => {
      this.context.drawImage(pic, this.imageCoordinateX, this.imageCoordinateY, this.imageWidth, this.imageHeight);
      this.image = this.myCanvas.nativeElement.toDataURL('image/png');
    });
  }

  enroll(imageAnchor: HTMLElement) {
    const url = this.myCanvas.nativeElement.toDataURL('image/png');
    imageAnchor.setAttribute('href', url);

    const fd = new FormData();
    this.myCanvas.nativeElement.toBlob(b => {
      fd.append('image', b, b.name + '.png');
      fd.append("municipality", this.stateName);
      fd.append("party", this.party);
      fd.append("name", this.inputName);
      this.httpClient.post(endPoint, fd).subscribe(res => {
          this.toastr.success('Вашата кандидатура е приета успешно');
        },
        error => {
          this.toastr.error(error);
        })
    })

  }

  checkComponent() {
    if (this.router.url.endsWith('gerb')) {
      this.templateSrc = '/assets/00001268701.jpg';
      this.nameCoordinateX = 500;
      this.nameCoordinateY = 380;
      this.stateFirstCoordinateX = 500;
      this.stateFirstCoordinateY = 320;
      this.stateSecondCoordinateX = 425;
      this.stateSecondCoordinateY = 529;
      this.imageCoordinateX = 55;
      this.imageCoordinateY = 56;
      this.imageWidth = 335;
      this.imageHeight = 380;
      this.cropHeight = 5;
      this.cropWidth = 4;
      this.nameColor = 'rgb(250, 254, 251)';
      this.stateNameColor = 'rgb(250, 254, 251)';
      this.stateNameFont = '30px Arial Regular';
      this.nameFont = '32px Arial';
      this.party = 'Герб';
      this.transformStateNameFont = x => x;
    } else if (this.router.url.endsWith('bsp')) {
      this.templateSrc = '/assets/asdasda sd asdasda.png';
      this.nameCoordinateX = 200;
      this.nameCoordinateY = 470;
      this.imageCoordinateX = 668;
      this.imageCoordinateY = 16;
      this.stateFirstCoordinateX = 80.925;
      this.stateFirstCoordinateY = 661.175;
      this.imageWidth = 338;
      this.imageHeight = 558;
      this.cropHeight = 6;
      this.cropWidth = 4;
      this.nameColor = 'rgb(160, 9, 19)';
      this.stateNameColor = 'rgb(160, 9, 19)';
      this.fontSize = '80px Arial Regular';
      this.stateNameColor = 'rgb(160, 9, 19)';
      this.stateNameFont = '80px Arial Regular';
      this.nameFont = '32px Arial';
      this.party = 'Бсп';
      this.transformStateNameFont = x => x;
    } else if (this.router.url.endsWith('ataka')) {
      this.templateSrc = '/assets/16724044.jpg';
      this.nameCoordinateX = 555.125;
      this.nameCoordinateY = 720.175;
      this.stateFirstCoordinateX = 598.875;
      this.stateFirstCoordinateY = 212.950;
      this.imageCoordinateX = 65.525;
      this.imageCoordinateY = 230.25;
      this.cropHeight = 5;
      this.cropWidth = 4;
      this.imageWidth = 451.9;
      this.imageHeight = 500.625;
      this.nameColor = 'rgb(250, 254, 251)';
      this.stateNameColor = 'rgb(250, 254, 251)';
      this.stateNameFont = '1200 48px Arial Regular';
      this.nameFont = 'bold 52px Arial';
      this.fontSize = '60px Arial Regular';
      this.party = 'Атака';
    } else if (this.router.url.endsWith('narodenSuiuz')) {
      this.templateSrc = '/assets/naroden_suiuz.jpg';
      this.nameCoordinateX = 50;
      this.nameCoordinateY = 600;
      this.imageCoordinateX = 664.4;
      this.imageCoordinateY = 179.1;
      this.stateFirstCoordinateX = 640;
      this.stateFirstCoordinateY = 661;
      this.imageWidth = 277.5;
      this.imageHeight = 434;
      this.cropHeight = 6;
      this.cropWidth = 4;
      this.nameColor = 'rgb(15, 58, 142)';
      this.stateNameColor = 'rgb(57, 164, 57)';
      this.fontSize = '50px Arial Regular';
      this.stateNameFont = '1200 50px Arial Regular';
      this.nameFont = 'bold 52px Arial';

      this.party = 'Народен съюз';
      this.transformStateNameFont = stateMame => stateMame.toUpperCase();
    } else {
      this.templateSrc = 'index';
    }
  }

  onFieldChange() {
    this.reset(false, () => {
      this.writeName(null);
      this.writeMunicipality();
      this.image = this.myCanvas.nativeElement.toDataURL('image/png');
      const pic = new Image();
      pic.src = this.croppedImage;
      pic.addEventListener('load', () => {
          this.context.drawImage(pic, this.imageCoordinateX, this.imageCoordinateY, this.imageWidth, this.imageHeight);
          this.image = this.myCanvas.nativeElement.toDataURL('image/png');
        }
      );
    });
  }
}
