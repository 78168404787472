import {Injectable} from "@angular/core";
import {LoginModel} from "../../model/login/login.model";
import {HttpClient} from "@angular/common/http";

const url = '/api/login';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  public constructor (
    private httpClient: HttpClient
  ) {}


  public login(loginModel: LoginModel) {
    return this.httpClient.post(url, loginModel, {observe: "response"});
  }




}
